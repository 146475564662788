<template>
  <div>
    <top-agent-self-agent-monthly-bills v-if="this.$store.getters.userRole === 'top_agent'" />
    <agency-self-agent-monthly-bills v-else-if="this.$store.getters.userRole === 'agency'" />
    <merchant-self-agent-monthly-bills v-else-if="this.$store.getters.userRole === 'merchant'" />
  </div>
</template>

<script>

export default {
  name: 'AgentMonthlyBills',
  components: {
    TopAgentSelfAgentMonthlyBills: () => import('@/views/agent_monthly_bills/top_agent_self/index'),
    AgencySelfAgentMonthlyBills: () => import('@/views/agent_monthly_bills/agency_self/index'),
    MerchantSelfAgentMonthlyBills: () => import('@/views/agent_monthly_bills/merchant_self/index')
  }
}
</script>
